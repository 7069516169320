import { IApplicationInsights } from '@microsoft/applicationinsights-web';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { SWOAuth0Client } from './client';
import { LoggerFactory } from '../logger';

export { createAutoLogin } from './auto-login'

export async function createAuth0Client(applicationInsights: IApplicationInsights, getLogger: LoggerFactory): Promise<Auth0Client> {
    const startTime = Date.now();
    const logger = getLogger('Auth0');

    logger.info(`Creating client...`);

    const auth0Client = new SWOAuth0Client({
        domain: __AUTH0_DOMAIN__,
        client_id: __AUTH0_CLIENT_ID__,
        redirect_uri: location.origin
    }, logger);

    await auth0Client.checkSession();
    await auth0Client.tryRedirectAfterLogin();

    logger.info(`Client ready.`);
    applicationInsights.trackMetric({name: `Auth0`, average: Date.now() - startTime});

    return auth0Client;
}
